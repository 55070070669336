import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader2 from "../../sections/utilities/Loader2";
import { getAuthToken } from "../../services/auth-header";
import { getProcessflowProgresses } from "../../jason-proof-of-concept/processflow-progresses/actions/get-processflow-progresses";
import { getDataFromProgressData } from "../../jason-proof-of-concept/wizard/wizard-container";
import { getEstimatedPayoutFromData } from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { getUserCompanies } from "../../user-companies/actions/get-user-companies";
import { useMemo, useState } from "react";
import { deleteUserCompany } from "../../user-companies/actions/delete-user-company";
import roleGroupService from "../../services/role-group.service";
import { useCompany } from "../hooks/use-company";
import { classNames } from "../../billing/utils";
import {
    BriefcaseIcon,
    EnvelopeIcon,
    PencilSquareIcon,
    PhoneIcon,
    UserIcon,
    UsersIcon,
} from "@heroicons/react/24/solid";
import { BasicInfo } from "../components/basic-info";
import { getUser } from "../../jason-proof-of-concept/users/actions/get-user";
import { Application } from "../components/application";
import { Notes } from "../components/notes";
import { useInteractions } from "../../interactions/hooks/use-interactions";
import { useAuth } from "../../auth/use-auth";
import { Calls } from "../components/calls";
import { Messages } from "../components/messages";
import { Members } from "../components/members";
import { getCompanyUsers } from "../actions/get-company-users";
import { updateUserCompany } from "../../user-companies/actions/update-user-commission";
import { useUsers } from "../../jason-proof-of-concept/users/hooks/use-users";
import { Billing } from "../components/billing";
import { paymentOptionsKey } from "../../billing/paymentOptions";

export const CompanyPage = () => {
    const { companyId: companyIdParam } = useParams();
    const url = new URL(window.location.href);
    const auth = useAuth();
    const user = auth.expectUser();
    const tab = url.searchParams.get("tab");
    const authToken = getAuthToken();
    if (!companyIdParam) {
        throw new Error("companyId is required");
    }
    const companyId = parseInt(companyIdParam);
    const navigate = useNavigate();

    const companyQuery = useCompany({ authToken, id: companyId });
    const company = companyQuery.data;

    const usersQuery = useQuery(["users", "company", companyId], async () => {
        const userCompanies = await getUserCompanies({ authToken, filters: { where: { companyId: companyId } } });
        const users = await getCompanyUsers({
            authToken,
            id: companyId,
            filters: { include: ["companyRoleGroups", "userCompanies"] },
        });
        return { users, userCompanies };
    });
    const users = useMemo(() => usersQuery.data?.users || [], [usersQuery.data?.users]);
    const userCompanies = useMemo(() => usersQuery.data?.userCompanies || [], [usersQuery.data?.userCompanies]);

    const ownerUserId = company?.ownedById;
    const ownerQuery = useUsers({ authToken, filters: { where: { id: ownerUserId } } }, { enabled: !!ownerUserId });
    const owner = ownerQuery.data?.[0];

    const affiliateUserId = owner?.affiliateUserId;
    const affiliateQuery = useUsers(
        { authToken, filters: { where: { id: affiliateUserId } } },
        { enabled: !!affiliateUserId },
    );
    const affiliate = affiliateQuery.data?.[0];

    const interactionsQuery = useInteractions({
        authToken,
        filters: { where: { or: [{ interaction_to: ownerUserId }, { companyId }] } },
        enabled: !!ownerUserId,
    });
    const notes = useMemo(() => (interactionsQuery.data || []).filter((i) => i.type === 3), [interactionsQuery.data]);

    const filteredNotes = useMemo(
        () =>
            notes.filter((note) => {
                const isCloser = note.isInternal
                    ? (user?.roleGroups || []).length > 0 &&
                      !user?.roleGroups?.find((rg) => rg.id === 15 || rg.id === 8)
                    : true;
                return isCloser;
            }),
        [notes, user?.roleGroups],
    );

    const calls = useMemo(() => (interactionsQuery.data || []).filter((i) => i.type === 2), [interactionsQuery.data]);
    const messages = useMemo(
        () => (interactionsQuery.data || []).filter((i) => i.type === 1),
        [interactionsQuery.data],
    );

    const progressQuery = useQuery(["billing-page-calcs2", { userId: ownerUserId }, authToken], async () => {
        if (!ownerUserId) {
            return {};
        }
        const group = 7;
        const [usersProgress] = await getProcessflowProgresses({
            authToken,
            filters: { userId: ownerUserId, group },
        });
        const data = getDataFromProgressData(usersProgress?.data as any);
        const estimatedAmount = getEstimatedPayoutFromData({ data });
        return { usersProgress, data, estimatedAmount };
    });

    const removeUserMutation = useMutation({
        mutationFn: async (userId: string) => {
            const [userCompany] = await getUserCompanies({
                authToken,
                filters: { where: { companyId, ercUserId: userId } },
            });
            await deleteUserCompany({ authToken, id: userCompany.id });
        },
    });

    const updateCommission = useMutation({
        mutationFn: async ({ userCompanyId, commission }: { userCompanyId: number; commission: number }) => {
            await updateUserCompany({
                id: userCompanyId,
                data: { commissionPercentage: commission },
                authToken,
            });
        },
    });

    const { usersProgress, data, estimatedAmount } = progressQuery.data || {};
    const applicationPercentageProgress = Math.round((usersProgress?.data?.percentageComplete || 0) * 100);

    const companyRolesQuery = useQuery(["companyRoles", { userId: ownerUserId, companyId }], async () => {
        const response = await roleGroupService.getAll();
        const roles = response?.data || [];
        return roles;
    });
    const companyRoles = useMemo(() => companyRolesQuery.data || [], [companyRolesQuery.data]);

    const navItems = useMemo(
        () => [
            {
                name: "Basic Information",
                description: "Basic information about the customer and a feed that shows all interactions",
                id: "basic",
                icon: UserIcon,
                content: () =>
                    company && owner ? (
                        <BasicInfo
                            company={company}
                            applicationPercentageProgress={applicationPercentageProgress}
                            owner={owner}
                            affiliate={affiliate}
                            onCompanyUpdated={() => {
                                companyQuery.refetch();
                            }}
                            onOwnerUpdated={() => {
                                ownerQuery.refetch();
                            }}
                            applicationData={data}
                        />
                    ) : null,
            },
            {
                name: "Application",
                description: "View and edit the customers application",
                id: "application",
                icon: BriefcaseIcon,
                content: () => {
                    return owner ? (
                        <Application
                            user={owner}
                            companyId={companyId}
                            onChange={() => {
                                progressQuery.refetch();
                            }}
                        />
                    ) : null;
                },
            },
            {
                name: "Members",
                description: "Users who belong to the company",
                id: "members",
                icon: UsersIcon,
                content: () => {
                    return (
                        company && (
                            <Members
                                company={company}
                                companyRoles={companyRoles as any[]}
                                userCompanies={userCompanies}
                                onUserAdded={() => {
                                    usersQuery.refetch();
                                }}
                                onUserUpdated={() => {
                                    usersQuery.refetch();
                                }}
                                onRemoveUser={async (userId) => {
                                    await removeUserMutation.mutateAsync(userId);
                                    usersQuery.refetch();
                                }}
                                onUserInvited={() => {
                                    usersQuery.refetch();
                                }}
                                onCommissionUpdated={async (userCompanyId, commission) => {
                                    await updateCommission.mutateAsync({
                                        userCompanyId,
                                        commission: Number(commission),
                                    });
                                }}
                                users={users}
                            />
                        )
                    );
                },
            },
            {
                name: "Billing",
                description: "Manage billing & finances",
                id: "billing",
                icon: BriefcaseIcon,
                content: () => {
                    return data && <Billing applicationData={data} />;
                },
            },
            {
                name: "Notes",
                description: "Any notes that you have taken about the customer.",
                id: "notes",
                icon: PencilSquareIcon,
                content: () => {
                    return (
                        ownerUserId && (
                            <Notes
                                user={user}
                                companyOwnerId={ownerUserId}
                                notes={filteredNotes}
                                company={company}
                                onNoteCreated={() => {
                                    interactionsQuery.refetch();
                                }}
                            />
                        )
                    );
                },
            },
            {
                name: "Phone Calls",
                description: "History of phone calls with the customer.",
                id: "phone",
                icon: PhoneIcon,
                content: () => {
                    return (
                        ownerUserId &&
                        company && (
                            <Calls
                                calls={calls}
                                companyOwnerId={ownerUserId}
                                userCompanies={userCompanies}
                                roles={companyRoles as any[]}
                                company={company}
                                onCallCreated={() => {
                                    interactionsQuery.refetch();
                                }}
                                users={users}
                            />
                        )
                    );
                },
            },
            {
                name: "Messages",
                description: "Message history with the customer.",
                id: "messages",
                icon: EnvelopeIcon,
                content: () => {
                    return (
                        company &&
                        ownerUserId && (
                            <Messages
                                company={company}
                                companyOwnerId={ownerUserId}
                                messages={messages}
                                onMessageCreated={() => {
                                    interactionsQuery.refetch();
                                }}
                                users={users}
                                userCompanies={userCompanies}
                                roles={companyRoles as any[]}
                            />
                        )
                    );
                },
            },
        ],
        [
            company,
            owner,
            applicationPercentageProgress,
            affiliate,
            data,
            companyQuery,
            ownerQuery,
            companyId,
            progressQuery,
            companyRoles,
            userCompanies,
            users,
            usersQuery,
            removeUserMutation,
            updateCommission,
            ownerUserId,
            user,
            filteredNotes,
            interactionsQuery,
            calls,
            messages,
        ],
    );

    const activeTab = tab || navItems[0].id;
    const activeNavItem = useMemo(() => navItems.find((item) => item.id === activeTab), [activeTab, navItems]);

    const ActiveItemComp = activeNavItem?.content;
    const Body = ActiveItemComp?.() || null;

    const ready = !!company && !!ActiveItemComp;

    return !ready ? (
        <Loader2 />
    ) : (
        <>
            <div className="flex flex-1 overflow-hidden bg-white shadow sm:rounded-lg h-full dark:bg-gray-800">
                <main className="flex flex-1 overflow-hidden">
                    <div className="flex flex-1 flex-col overflow-y-auto xl:overflow-hidden">
                        <div className="flex flex-1 xl:overflow-hidden">
                            <nav
                                aria-label="Sections"
                                className="min-h-screen w-96 flex-shrink-0 border-r border-blue-gray-200 dark:border-gray-900 bg-white xl:flex xl:flex-col dark:bg-gray-800"
                            >
                                <div className="flex h-16 flex-shrink-0 items-center border-b border-gray-200 px-6 dark:border-gray-900">
                                    <p className="text-lg font-medium text-blue-gray-900 dark:text-gray-400">
                                        {company.name}
                                    </p>
                                </div>
                                <div className="min-h-0 flex-1 overflow-y-auto">
                                    {navItems.map((item) => {
                                        const isActive = activeNavItem && item.id === activeNavItem?.id;
                                        return (
                                            <button
                                                key={item.name}
                                                onClick={() => {
                                                    navigate(`/companies/${companyId}?tab=${item.id}`);
                                                }}
                                                className={classNames(
                                                    isActive
                                                        ? "bg-slate-500 bg-opacity-10 dark:bg-indigo-900 dark:bg-opacity-30"
                                                        : "hover:bg-blue-50 hover:bg-opacity-50 hover:dark:bg-blue-900 hover:dark:bg-opacity-30",
                                                    "flex p-4 border-b border-blue-gray-200 text-left w-full dark:border-gray-900",
                                                )}
                                                aria-current={isActive ? "page" : undefined}
                                            >
                                                <item.icon
                                                    className="-mt-0.5 h-6 w-6 flex-shrink-0 text-blue-gray-400"
                                                    aria-hidden="true"
                                                />
                                                <div className="ml-3 text-sm">
                                                    <p className="font-medium text-gray-900 dark:text-gray-300">
                                                        {item.name}
                                                    </p>
                                                    <p className="mt-1 text-gray-500">{item.description}</p>
                                                </div>
                                            </button>
                                        );
                                    })}
                                </div>
                            </nav>
                            <div className="flex-1 xl:overflow-y-auto">
                                <div className="flex-1 py-5 px-0 sm:px-6 lg:px-0">{Body}</div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};
