import { Currency } from "../../billing/components";
import { getTiers, paymentOptionsKey } from "../../billing/paymentOptions";
import {
    affiliateCommissionMultiplier,
    clearErcFeesMultiplier,
    getEstimatedPayoutFromData,
} from "../../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { Table, Td, Tr } from "../../layout/table";

export const Billing = ({ applicationData }: { applicationData: Record<string, any> }) => {
    const estimatedAmount = getEstimatedPayoutFromData({ data: applicationData });
    const paymentOption = applicationData[paymentOptionsKey];
    const tiers = getTiers({ estimatedAmount });
    const tier = tiers.find((t) => t.id === paymentOption);

    const clearErcFee = estimatedAmount * clearErcFeesMultiplier;
    const affiliatePayout = clearErcFee * affiliateCommissionMultiplier;

    return (
        <div>
            <Table>
                <Tr>
                    <Td style={{ width: 200, color: "gray", verticalAlign: "top" }}>Deal value</Td>
                    <Td>
                        <b>
                            <Currency amount={estimatedAmount} />
                        </b>
                    </Td>
                </Tr>
                <Tr>
                    <Td style={{ width: 200, color: "gray", verticalAlign: "top" }}>Payout structure</Td>
                    <Td>
                        <Table>
                            <Tr>
                                <Td style={{ width: 200, color: "gray", verticalAlign: "top" }}>Client payout</Td>
                                <Td>
                                    <b>
                                        <Currency amount={estimatedAmount} />
                                    </b>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: 200, color: "gray", verticalAlign: "top" }}>ClearERC gross fee</Td>
                                <Td>
                                    <b>
                                        <Currency amount={clearErcFee} />{" "}
                                    </b>
                                    <span style={{ color: "gray" }}>
                                        ({clearErcFeesMultiplier * 100}% of <Currency amount={estimatedAmount} /> - The
                                        client payout)
                                    </span>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td style={{ width: 200, color: "gray", verticalAlign: "top" }}>Affiliate payout</Td>
                                <Td>
                                    <b>
                                        <Currency amount={affiliatePayout} />{" "}
                                    </b>
                                    <span style={{ color: "gray" }}>
                                        ({affiliateCommissionMultiplier * 100}% of <Currency amount={clearErcFee} /> -
                                        the ClearERC gross fee)
                                    </span>
                                </Td>
                            </Tr>
                        </Table>
                    </Td>
                </Tr>
            </Table>
        </div>
    );
};
